$(document).ready(function(){

    // for header dropdown and other dropdowns
    $(document).on('click','.dropdown-btn',function(){
        var dropdown = $(this).parent();
        clickAway(dropdown);
        if (dropdown.hasClass("isOpen").length) {
            dropdown.removeClass("isOpen");
        } else {
            dropdown.addClass("isOpen");
        }
    });


    function clickAway(element) {
        $(document).on("mousedown", (event) => {
            if (!element.has(event.target).length) {
                element.removeClass("isOpen");
            }
        });
    };

    //search wrap
    $(document).on('click', '.burgerMenuSearch',function () {
        if ($("#header-search").hasClass("search-responsive-wrap-open")) {
            $("#header-search").removeClass("search-responsive-wrap-open")
        }else{
            $("#header-search").addClass("search-responsive-wrap-open")
        }
    })



    //show and hide menu and input fiels
    $(document).on('click', '#toggle-btn', function (event) {
        event.preventDefault();
        if ($(".extra-content").css('display') === 'block') {
            $(".extra-content").css("display", "none")
        } else {
            $(".extra-content").css("display", "block")
        }

        var icon = $(this).find(".rotate");
        icon.toggleClass("down");
    })

});





$(window).on("resize", function (event) {
    if ($(window).width() > 1024) {
        $("#menu-line-upper").removeClass("line-upper-rotated")
        $("#menu-line-lower").removeClass("line-lower-rotated")
        $("#header-responsive").removeClass("header-responsive-open")
        $("#header-search").removeClass("search-responsive-wrap-open")
    }
});

